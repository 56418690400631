<template>
  <b-dropdown
    :disabled="disabled"
    variant="danger"
    class="delete-dropdown"
    size="sm"
    no-caret
    right
  >
    <template v-slot:button-content>
      <span class="pi pi-trash p-button-icon p-button-icon-left"></span>
      <span class="p-button-label ml-1">
        <slot name="buttonText"> Löschen</slot>
      </span>
    </template>
    <b-dropdown-text class="fw-semi-bold">
      Löschen bestätigen
    </b-dropdown-text>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-text class="small">
      Kann nicht rückgängig gemacht werden! Auf die unten stehende Schaltfläche klicken um löschen
      zu bestätigen.
    </b-dropdown-text>
    <b-dropdown-group>
      <b-button :disabled="disabled" variant="danger" @click="confirm">Löschen</b-button>
    </b-dropdown-group>
  </b-dropdown>
</template>

<script>
export default {
  name: 'ConfirmDeleteButton',
  props: {
    id: [String, Number],
    disabled: Boolean,
    confirmCallback: Function,
  },
  methods: {
    confirm() {
      if (this.id) {
        // the old way
        this.$emit('confirm-delete-button:confirmed', this.id);
      }

      if (this.confirmCallback) {
        // the new way
        this.confirmCallback();
      }
    },
  },
};
</script>

<style lang="scss">
.delete-dropdown .dropdown-menu {
  padding: 15px;
  width: 230px;
}
.delete-dropdown button {
  width: 100%;
}
</style>

<style scoped lang="scss">
::v-deep .btn-sm {
  font-size: 1rem;
  padding: 0.6rem 1rem 0.5rem 1rem;
  display: inline-flex;
  line-height: 21px;
}
::v-deep .pi {
  position: relative;
  top: 3px;
}
</style>
